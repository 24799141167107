import { CREATE_JOB } from "@/store/actions";
import { TEAMMATES } from "./teammates";

export const SEARCH_LEVEL = {
  SUPERFICIAL: "superficial",
  AVERAGE: "average",
  DEEP: "deep",
};

export const SEARCH_ENGINES = {
  GOOGLE: "google",
  YOUTUBE: "youtube",
};

export const TASKS = {
  // Analysis
  ANALYSIS_COMPETITOR: "analysisCompetitor",
  ANALYSIS_MARKET: "analysisMarket",

  // Brainstorm
  BRAINSTORM_BLOG_POSTS: "brainstormBlogPosts",
  BRAINSTORM_FACEBOOK_POSTS: "brainstormFacebookPosts",
  BRAINSTORM_FACEBOOK_ADS: "brainstormFacebookAds",

  // Strategize
  STRATEGY_AUDIENCE: "strategyAudience",

  // Scrape
  VISIT_WEBSITE: "visitWebsite",
  VISIT_WEBSITE_PAGE: "visitWebsitePage",

  // Research
  CREATE_MINDMAP: "createMindmap",

  // Search
  SEARCH_YOUTUBE: "searchYouTube",
  SEARCH_GOOGLE: "searchGoogle",
  SEARCH_GOOGLE_MAPS: "searchGoogleMaps",
  SEARCH_GOOGLE_TRENDS: "searchGoogleTrends",
  SEARCH_GOOGLE_ADS: "searchGoogleAds",
  SEARCH_FACEBOOK_ADS: "searchFacebookAds",
  SEARCH_APPLE_STORE: "searchAppleStore",
  SEARCH_TWITTER: "searchTwitter",
  SEARCH_AMAZON: "searchAmazon",
  SEARCH_G2: "searchG2",

  // Outline
  OUTLINE_BLOG_POST: "outlineBlogPost",

  // Write
  WRITE_SALEPAGE: "writeSalepage",
  WRITE_BLOG_POST: "writeBlogPost",
  WRITE_BLOG_POST_SEO: "writeBlogPostSEO",
  WRITE_BLOG_POST_SEO_LONG: "writeBlogPostSEOLong",
  WRITE_BLOG_POST_SEO_DESCRIPTION: "writeBlogPostSEODescription",
  WRITE_FACEBOOK_POST: "writeFacebookPost",
  WRITE_FACEBOOK_AD: "writeFacebookAd",
  WRITE_INSTAGRAM_POST: "writeInstagramPost",
  WRITE_TWITTER_POST: "writeTwitterPost",
  WRITE_TWITTER_THREAD: "writeTwitterThread",
  WRITE_TWITTER_AD: "writeTwitterAd",
  WRITE_LINKEDIN_POST: "writeLinkedinPost",
  WRITE_LINKEDIN_AD: "writeLinkedinAd",
  WRITE_GOOGLE_AD: "writeGoogleAd",
  WRITE_YOUTUBE_POST: "writeYoutubePost",
  WRITE_YOUTUBE_INFO: "writeYoutubeInfo",

  // Visual
  CREATE_LOGO: "createLogo",
  DRAW_BLOG_POST_COVER: "createBlogPostCover",

  // Transcribe
  TRANSCRIBE_MEDIA: "transcribeMedia",
  TRANSCRIBE_YOUTUBE: "transcribeYoutube",

  // Summarize
  SUMMARIZE_TEXT: "summarizeText",
  SUMMARIZE_PDF: "summarizePdf",

  // Translate
  TRANSLATE_TEXT: "translateText",

  // Check
  DUPLICATE_TEXT: "duplicateText",

  // Support
  RETRIEVAL_QA: "retrievalQA",

  // Talk
  TALK: "talk",

  // Suggestions
  SUGGEST_BLOG_POST_COVER: "suggestBlogPostCover",
  SUGGEST_BLOG_POST_KEYWORDS: "suggestBlogPostKeywords",
};

export const TOOLS = {
  RUN_ANALYSIS: "run-analysis",
  RUN_OUTLINE: "run-outline",
  RUN_SUGGEST: "run-suggest",
  RUN_IMAGE: "run-image",
  RUN_WRITE: "run-write",
  RUN_WRITE_LONG: "run-write-long",
  RUN_SEARCH: "run-search",
  SEARCH_WEBSITE: "search-website",
  FILE_RETRIEVAL: "file-retrieval",
  FILE_TRANSCRIPTION: "file-transcription",
  YOUTUBE_CHANNEL: "youtube-channel",
  YOUTUBE_TRANSCRIPTION: "youtube-transcription",
  WRITE_BLOG_POST: "write-blog-post",
  CREATE_DOCUMENT: "create-document",
  QA_RETRIEVAL: "qa-retrieval",
  TEXT_SUMMARY: "text-summary",
  TEXT_CONCAT: "text-concat",
  TASK_CREATOR: "task-creator",
};

export const ANALYSIS_IDS = {
  FOUR_P: "fourP",
  SWOT: "swot",
  SEARCH_GOOGLE: "searchGoogle",
  SUMMARY: "summary",
};

export const OUTLINE_IDS = {
  MINDMAP_DRAFT: "mindmapDraft",
  BLOG_POST: "blogPost",
};

export const WRITING_IDS = {
  SALEPAGE: "salepage",
  BLOG_POST: "blogPost",
  BLOG_POST_SEO: "blogPostSEO",
  BLOG_POST_META_TITLE: "blogPostMetaTitle",
  BLOG_POST_META_PERMALINK: "blogPostMetaPermalink",
  BLOG_POST_META_DESCRIPTION: "blogPostMetaDescription",
  FACEBOOK_POST: "facebookPost",
  FACEBOOK_AD: "facebookAd",
  INSTAGRAM_POST: "instagramPost",
  TWITTER_POST: "twitterPost",
  TWITTER_THREAD: "twitterThread",
  TWITTER_AD: "twitterAd",
  LINKEDIN_POST: "linkedinPost",
  LINKEDIN_AD: "linkedinAd",
  GOOGLE_AD: "googleAd",
  YOUTUBE_POST: "youtubePost",
  YOUTUBE_INFO: "youtubeInfo",
};

export const SUGGESTIONS_IDS = {
  SUGGEST_BLOG_POST_COVER: "suggestBlogPostCover",
  SUGGEST_BLOG_POST_KEYWORDS: "suggestBlogPostKeywords",
};

export const TASKS_OPTIONS_COMMAND = [
  // Diverse
  { id: TASKS.TALK, name: "Talk", description: "Have a conversation with me", category: "Diverse", condition: () => true },
  {
    id: TASKS.SUMMARIZE_TEXT,
    name: "Summarize",
    description: "Summarize a text and get the key points.",
    icon: "",
    category: "Diverse",
    // creditsEstimation: "24 credits per minute for an audio and 36 credits per minute for a video",
    condition: (isPublic) => !isPublic,
  },
  {
    id: TASKS.TRANSCRIBE_MEDIA,
    name: "Transcribe",
    description: "Create a transcription of an audio or a video.",
    icon: "",
    category: "Diverse",
    creditsEstimation: "24 credits per minute for an audio and 36 credits per minute for a video",
    condition: (isPublic) => !isPublic,
  },
  {
    id: TASKS.TRANSLATE_TEXT,
    name: "Translate",
    description: "Translate text from one language to another.",
    category: "Diverse",
    condition: (isPublic) => !isPublic,
  },
  // {
  //   id: TASKS.TRANSCRIBE_YOUTUBE,
  //   name: "Transcribe YouTube",
  //   description: "Create a transcription of a YouTube video.",
  //   icon: "",
  //   category: "Diverse",
  //   // creditsEstimation: "24 credits per minute for an audio and 36 credits per minute for a video",
  //   condition: (isPublic) => !isPublic,
  // },
  /* {
    id: TASKS.DUPLICATE_TEXT,
    name: "Check for Duplicate Text",
    description: "Check text for potential duplication or plagiarism.",
    category: "Diverse",
    condition: (isPublic) => !isPublic,
  }, */

  // Analysis
  {
    id: TASKS.ANALYSIS_COMPETITOR,
    name: "Competitor Analysis",
    description: "Insert the URL of a competitor to make a deep analysis of their website and overall marketing strategy.",
    icon: "",
    badges: [{ variant: "primary", text: "flow" }],
    category: "Analysis",
    creditsEstimation: "About 1.000 credits",
    condition: (isPublic) => !isPublic,
  },
  /*  {
    id: TASKS.ANALYSIS_MARKET,
    name: "Market Analysis",
    description: "Analyze the market trends and data",
    category: "Analysis",
    condition: (isPublic) => !isPublic,
  }, */

  // Brainstorm
  /*  {
    id: TASKS.BRAINSTORM_BLOG_POSTS,
    name: "Brainstorm Blog Post Ideas",
    description: "Generate ideas for blog posts",
    category: "Brainstorm",
    condition: (isPublic) => !isPublic,
  }, */
  /* {
    id: TASKS.BRAINSTORM_FACEBOOK_POSTS,
    name: "Brainstorm Facebook Post Ideas",
    description: "Generate ideas for Facebook posts",
    category: "Brainstorm",
    condition: (isPublic) => !isPublic,
  }, */
  /* {
    id: TASKS.BRAINSTORM_FACEBOOK_ADS,
    name: "Brainstorm Facebook Ad Ideas",
    description: "Generate ideas for Facebook ads",
    category: "Brainstorm",
    condition: (isPublic) => !isPublic,
  }, */

  /*  {
    id: TASKS.CREATE_MINDMAP,
    name: "Create Mindmap",
    description: "",
    category: "Research",
    condition: (isPublic) => !isPublic,
  }, */
  // Stragize

  // Scrape

  // Search
  /* {
    id: TASKS.SEARCH_GOOGLE,
    name: "Search Google",
    description: "Perform a Google search to find informations on a specific topic",
    category: "Search",
    condition: (isPublic) => !isPublic,
  }, */
  /* {
    id: TASKS.SEARCH_YOUTUBE,
    name: "Search YouTube",
    description: "Look up videos on YouTube",
    category: "Search",
    condition: (isPublic) => !isPublic,
  }, */

  // Outline
  {
    id: TASKS.OUTLINE_BLOG_POST,
    name: "Outline a blog post",
    description: "Prepare a coherent outline for a long blog post on a specific subject.",
    category: "Outline",
    // creditsEstimation: "About 300 credits for 800 words",
    condition: (isPublic) => !isPublic,
  },
  // Write
  /* {
    id: TASKS.WRITE_SALEPAGE,
    name: "Write a sale page",
    description: "Develop a comprehensive sales page tailored for a designated product.",
    category: "Write",
    condition: (isPublic) => !isPublic,
  }, */
  /* {
    id: TASKS.WRITE_FACEBOOK_POST,
    name: "Write a facebook post",
    description: "Design a captivating Facebook advertisement targeting specific product promotion.",
    category: "Write",
    condition: (isPublic) => !isPublic,
  }, */

  {
    id: TASKS.WRITE_BLOG_POST,
    name: "Write a blog post",
    description: "Craft an engaging and informative content tailored for your blog, focusing on relevant topics.",
    category: "Write",
    creditsEstimation: "About 300 credits for 800 words",
    condition: (isPublic) => !isPublic,
  },
  {
    id: TASKS.WRITE_BLOG_POST_SEO,
    name: "Write a blog post - SEO optimized",
    description: "Write a short blog post (around 800 words) focusing on specific keywords.",
    category: "Write",
    creditsEstimation: "About 300 credits for 800 words",
    condition: (isPublic) => !isPublic,
  },
  {
    id: TASKS.WRITE_BLOG_POST_SEO_LONG,
    name: "Write a pilar blog post - SEO optimized",
    description: "Write a long blog post (around 4000 words) focusing on specific keywords.",
    category: "Write",
    // creditsEstimation: "About 300 credits for 800 words",
    condition: (isPublic) => !isPublic,
  },

  // Visual
  {
    id: TASKS.DRAW_BLOG_POST_COVER,
    name: "Blog Post Cover",
    description: "Create a a blog post cover.",
    icon: "",
    category: "Visual",
    creditsEstimation: "160 credits for a squared image and 320 for an horizontal or vertical image",
    // creditsEstimation: "24 credits per minute for an audio and 36 credits per minute for a video",
    condition: (isPublic) => !isPublic,
  },

  // Support
  // {
  //   id: TASKS.RETRIEVAL_QA,
  //   name: "Answer A Question",
  //   description: "Give a precise and useful response to a query using one of your provided document.",
  //   icon: "",
  //   category: "Support",
  //   // creditsEstimation: "24 credits per minute for an audio and 36 credits per minute for a video",
  //   condition: (isPublic) => !isPublic,
  // },
];

// TODO: Merge it inside DEFAULT_REPLY
export const DEFAULT_FIRST_MESSAGE = {
  [TASKS.ANALYSIS_COMPETITOR]: "All right, let's look at your competitor!",
  [TASKS.SEARCH_GOOGLE]: "All right, let's do a Google search.",
  [TASKS.OUTLINE_BLOG_POST]: "All right, let's create an outline. Which topic do you want to explore?",
  [TASKS.WRITE_SALEPAGE]: "All right, let's crush that. First of all, I need to know which product we are going to work on.",
  [TASKS.WRITE_BLOG_POST]: "All right, let's write a new blog post!",
  [TASKS.WRITE_BLOG_POST_SEO]: "All right, let's write a new blog post!",
  [TASKS.WRITE_BLOG_POST_SEO_LONG]: "All right, let's write a new core blog post!",
  [TASKS.WRITE_FACEBOOK_POST]: "All right, let's write a new facebook post!",
  [TASKS.DRAW_BLOG_POST_COVER]: "All right, let's design a blog post cover!",
  [TASKS.RETRIEVAL_QA]: "All right, what's the question?",
  [TASKS.TRANSCRIBE_MEDIA]: "All right, let's do some transcription work! Drop your file and I'll take care of it.",
  [TASKS.TRANSCRIBE_YOUTUBE]: "What's the URL of the YouTube video?.",
  [TASKS.SUMMARIZE_TEXT]: "All right, let's do some summarization! Copy paste your text and I'll take care of it.",
  [TASKS.TALK]: "All right, what can I do for you?",
};

//
// IMPORTANT:
// Everytime a new function is being added, add it also in the backend
//

export const DEFAULT_REPLY = {
  [TASKS.ANALYSIS_COMPETITOR]: {
    format: "form",
    headline: "Which Competitor Should I Look At?",
    firstMessage: "Check this one", // Only the prefix will be translated
    firstMessageSuffix: "<a href='$url' target='_blank'><span class='url-analysis'>$url</a>",
    stateMachine: {
      id: "analysis-competitor",
      initialStep: "step-url",
      context: {
        currentStep: "step-url",
        formData: {
          url: "",
        },
      },
      steps: {
        "step-url": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Url",
                name: "url",
                validators: ["required", "url"],
                label: "",
                attributes: {
                  placeholder: "Insert the URL of your competitor...",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          id: "webscrapping",
          status: false,
          text: "Visiting competitor website",
          handler: TOOLS.SEARCH_WEBSITE,
        },
        {
          id: "fourPAnalysis",
          status: false,
          text: "Running a 4P Analysis",
          handler: TOOLS.RUN_ANALYSIS,
          args: { analysisId: ANALYSIS_IDS.FOUR_P, resultKey: "fourpAnalysis" },
        },
        {
          id: "swotAnalysis",
          status: false,
          text: "Running a SWOT Analysis",
          handler: TOOLS.RUN_ANALYSIS,
          args: { analysisId: ANALYSIS_IDS.SWOT, resultKey: "swotAnalysis" },
        },
        {
          id: "summary",
          status: false,
          text: "Writing down the report",
          handler: TOOLS.RUN_ANALYSIS,
          args: { analysisId: ANALYSIS_IDS.SUMMARY, resultKey: "content" },
        },
        {
          id: "document",
          status: false,
          text: "Creating the final report",
          handler: TOOLS.CREATE_DOCUMENT,
          args: { name: "webscrappingTitle", content: "summaryContent" },
        },
      ],
      message: {
        type: "document",
        content: `Done! The competitor analysis of <a href="$HOST/$projectId/documents/$documentDocumentId" class="document-link">
        <span class='document-name'>competitor-analysis-$url <em>(ID: $documentDocumentId)</em></span></a> is ready.`,
      },
    },
  },
  [TASKS.SEARCH_GOOGLE]: {
    format: "form",
    headline: "What are we looking for?",
    firstMessage: "$searchLevel $query",
    stateMachine: {
      id: "search-google",
      initialStep: "step-url",
      context: {
        currentStep: "step-url",
        formData: {
          searchLevel: SEARCH_LEVEL.SUPERFICIAL,
          query: "",
        },
      },
      steps: {
        "step-url": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Select",
                name: "searchLevel",
                validators: ["required"],
                label: "Search Level",
                attributes: {
                  options: [
                    { value: SEARCH_LEVEL.SUPERFICIAL, text: "Superficial" },
                    { value: SEARCH_LEVEL.AVERAGE, text: "Average" },
                    { value: SEARCH_LEVEL.DEEP, text: "Deep" },
                  ],
                },
              },
              {
                componentName: "Input",
                name: "query",
                validators: ["required"],
                label: "Keyword",
                attributes: {
                  placeholder: "What should we be searching for?",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          id: "search",
          status: false,
          text: "Searching Google",
          handler: TOOLS.RUN_RESEARCH,
          args: { engine: SEARCH_ENGINES.GOOGLE },
        },
        /* {
          id: "analysis",
          status: false,
          text: "Summarize Google Search",
          handler: TOOLS.RUN_ANALYSIS,
          args: { analysisId: ANALYSIS_IDS.SEARCH_GOOGLE, resultKey: "content" },
        }, */
      ],
      message: {
        type: "document",
        content: `Done! The search is done`,
      },
    },
  },
  [TASKS.CREATE_MINDMAP]: {
    format: "form",
    headline: "What are we looking for?",
    firstMessage: "$searchLevel $centralIdea",
    stateMachine: {
      id: "create-mindmap",
      initialStep: "step-url",
      context: {
        currentStep: "step-url",
        formData: {
          searchLevel: SEARCH_LEVEL.SUPERFICIAL,
          youtubeChannelName: "",
          centralIdea: "",
        },
      },
      steps: {
        "step-url": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Select",
                name: "searchLevel",
                validators: ["required"],
                label: "Search Level",
                attributes: {
                  options: [
                    { value: SEARCH_LEVEL.SUPERFICIAL, text: "Superficial" },
                    { value: SEARCH_LEVEL.AVERAGE, text: "Average" },
                    { value: SEARCH_LEVEL.DEEP, text: "Deep" },
                  ],
                },
              },
              {
                componentName: "Input",
                name: "youtubeChannelName",
                validators: ["required"],
                label: "youtubeChannelName",
                attributes: {
                  placeholder: "YouTube Channel Id",
                },
              },
              {
                componentName: "Message",
                name: "centralIdea",
                validators: ["required"],
                label: "Central Idea",
                attributes: {
                  placeholder: "What should we be searching for?",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          id: "youtube",
          status: false,
          text: "Fetch YouTube videos",
          handler: TOOLS.YOUTUBE_CHANNEL,
          // args: { outlineId: OUTLINE_IDS.MINDMAP_DRAFT },
        },
        {
          status: false,
          id: "creator",
          text: "Create new tasks",
          handler: TOOLS.TASK_CREATOR,
          args: {
            newTasksVariable: { key: "youtubeVideosList", handler: TOOLS.YOUTUBE_TRANSCRIPTION, args: {} },
          },
        },
        /*   {
          id: "outline",
          status: false,
          text: "Create outline",
          handler: TOOLS.CREATE_MINDMAP,
          args: { outlineId: OUTLINE_IDS.MINDMAP_DRAFT },
        }, */
        /*  {
          id: "search",
          status: false,
          text: "Searching Google",
          handler: TOOLS.RUN_RESEARCH,
          args: { engine: SEARCH_ENGINES.GOOGLE },
        }, */
        /* {
          id: "analysis",
          status: false,
          text: "Summarize Google Search",
          handler: TOOLS.RUN_ANALYSIS,
          args: { analysisId: ANALYSIS_IDS.SEARCH_GOOGLE, resultKey: "content" },
        }, */
      ],
      message: {
        type: "document",
        content: `Done!\n$youtubeContent`,
      },
    },
  },
  [TASKS.OUTLINE_BLOG_POST]: {
    format: "form",
    headline: "Tell Me More",
    firstMessage: `$description`,
    stateMachine: {
      id: "outline-blog-post",
      initialStep: "step-post",
      context: {
        currentStep: "step-post",
        formData: {
          description: "",
          references: [],
        },
      },
      steps: {
        "step-post": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Message",
                name: "description",
                validators: ["required"],
                label: "Description",
                attributes: {
                  placeholder: "Explain which subject you'd like to explore...",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          id: "outline",
          status: false,
          text: "Writing outline",
          handler: TOOLS.RUN_OUTLINE,
          args: { outlineId: OUTLINE_IDS.BLOG_POST },
        },
        {
          id: "document",
          status: false,
          text: "Creating document",
          handler: TOOLS.CREATE_DOCUMENT,
          args: { name: "outlineName", content: "outlineContent" },
        },
      ],
      message: {
        type: "document",
        content: `Done! The blog post outline <a href="$HOST/$projectId/documents/$documentDocumentId" class="document-link">
<span class='document-name'>$outlineName <em>(ID: $documentDocumentId)</em></span></a> is ready.`,
      },
    },
  },
  [TASKS.WRITE_BLOG_POST]: {
    format: "form",
    headline: "Tell Me More",
    firstMessage: `Write a blog post and follow those requirements:`,
    firstMessageSuffix: `Structure: $structureId
Description: $description
`,
    stateMachine: {
      id: "write-blog-post",
      initialStep: "step-post",
      context: {
        currentStep: "step-post",
        formData: {
          teammateId: null,
          structureId: "how-to",
          description: "",
          references: [],
        },
      },
      steps: {
        "step-post": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Select",
                name: "teammateId",
                validators: [],
                label: "AI Teammate",
                attributes: {
                  options: [],
                },
              },
              {
                componentName: "Select",
                name: "structureId",
                validators: ["required"],
                label: "Structure",
                attributes: {
                  options: [
                    { value: "how-to", text: "How-to Guide" },
                    { value: "listicle", text: "Listicle" },
                    { value: "news-update", text: "News Update" },
                    { value: "opinion", text: "Opinion Piece" },
                    { value: "case-study", text: "Case Study" },
                    { value: "tutorial", text: "Tutorial" },
                    { value: "comparison", text: "Comparison Post" },
                    { value: "FAQ", text: "FAQ" },
                    { value: "checklist", text: "Checklist" },
                    { value: "other", text: "Other" },
                  ],
                },
              },
              {
                componentName: "Message",
                name: "description",
                validators: ["required"],
                label: "Description",
                attributes: {
                  placeholder: "Insert the description of your blog post...",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          id: "post",
          status: false,
          text: "Writing blog post",
          handler: TOOLS.RUN_WRITE,
          args: { writingId: WRITING_IDS.BLOG_POST },
        },
        {
          id: "document",
          status: false,
          text: "Creating document",
          handler: TOOLS.CREATE_DOCUMENT,
          args: { name: "postName", content: "postContent" },
        },
      ],
      message: {
        type: "document",
        content: `Done! The blog post <a href="$HOST/$projectId/documents/$documentDocumentId" class="document-link">
<span class='document-name'>$postName <em>(ID: $documentDocumentId)</em></span></a> is ready.`,
      },
    },
  },
  [TASKS.WRITE_BLOG_POST_SEO]: {
    format: "form",
    headline: "Tell Me More",
    firstMessage: `Write a blog post and follow those requirements:`,
    firstMessageSuffix: `Structure: $structureId
Description: $description

$keywords
`,
    stateMachine: {
      id: "write-blog-post-seo",
      initialStep: "step-post",
      context: {
        currentStep: "step-post",
        formData: {
          teammateId: null,
          structureId: "how-to",
          name: "",
          description: "",
          keywords: "",
          references: [],
        },
      },
      steps: {
        "step-post": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Select",
                name: "teammateId",
                validators: [],
                label: "AI Teammate",
                attributes: {
                  options: [],
                },
              },
              {
                componentName: "Select",
                name: "structureId",
                validators: ["required"],
                label: "Structure",
                attributes: {
                  options: [
                    { value: "how-to", text: "How-to Guide" },
                    { value: "listicle", text: "Listicle" },
                    { value: "news-update", text: "News Update" },
                    { value: "opinion", text: "Opinion Piece" },
                    { value: "case-study", text: "Case Study" },
                    { value: "tutorial", text: "Tutorial" },
                    { value: "comparison", text: "Comparison Post" },
                    { value: "FAQ", text: "FAQ" },
                    { value: "checklist", text: "Checklist" },
                    { value: "other", text: "Other" },
                  ],
                },
              },
              /* {
                componentName: "Input",
                name: "name",
                validators: [],
                label: "Name",
                attributes: {
                  placeholder: "Insert the name of your blog post (optional)...",
                },
              }, */
              {
                componentName: "Message",
                name: "description",
                validators: ["required"],
                label: "Description",
                attributes: {
                  placeholder: "Insert the description of your blog post...",
                },
              },
              {
                componentName: "SeoKeywords",
                name: "keywords",
                validators: ["required"],
                label: "Keywords",
                attributes: {
                  placeholder: "Insert the keywords you'd like to optimize for (separate each keyword with a comma)...",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          status: false,
          id: "post",
          text: "Writing blog post",
          handler: TOOLS.RUN_WRITE,
          args: { writingId: WRITING_IDS.BLOG_POST_SEO },
        },
        {
          status: false,
          id: "metaTitle",
          text: "Writing meta title",
          handler: TOOLS.RUN_WRITE,
          args: { writingId: WRITING_IDS.BLOG_POST_META_TITLE },
        },
        {
          status: false,
          id: "metaPermalink",
          text: "Writing meta permalink",
          handler: TOOLS.RUN_WRITE,
          args: { writingId: WRITING_IDS.BLOG_POST_META_PERMALINK },
        },
        {
          status: false,
          id: "metaDescription",
          text: "Writing meta description",
          handler: TOOLS.RUN_WRITE,
          args: { writingId: WRITING_IDS.BLOG_POST_META_DESCRIPTION },
        },
        {
          status: false,
          id: "concat",
          text: "Merge post and description",
          handler: TOOLS.TEXT_CONCAT,
          args: {
            variables: [
              { key: "metaTitleContent", prefix: "<h1>Meta Title</h1>" },
              { key: "metaPermalinkContent", prefix: "<h1>Meta Permalink</h1>" },
              { key: "metaDescriptionContent", prefix: "<h1>Meta Description</h1>" },
              { key: "keywords", prefix: "<h1>Meta Keywords</h1>" },
              { key: "postContent", prefix: "<h1>Content</h1>" },
            ],
          },
        },
        {
          status: false,
          id: "document",
          text: "Creating document",
          handler: TOOLS.CREATE_DOCUMENT,
          args: { name: "postName", content: "concatContent" },
        },
      ],
      message: {
        type: "document",
        content: `Done! The blog post <a href="$HOST/$projectId/documents/$documentDocumentId" class="document-link">
<span class='document-name'>$postName <em>(ID: $documentDocumentId)</em></span></a> is ready.`,
      },
    },
  },
  [TASKS.WRITE_BLOG_POST_SEO_LONG]: {
    format: "form",
    headline: "Tell Me More",
    firstMessage: `Write a blog post and follow those requirements:`,
    firstMessageSuffix: `Structure: $structureId
Description: $description

$keywords
`,
    stateMachine: {
      id: "write-blog-post-seo-long",
      initialStep: "step-post",
      context: {
        currentStep: "step-post",
        formData: {
          teammateId: null,
          structureId: "how-to",
          name: "",
          description: "",
          references: [],
        },
      },
      steps: {
        "step-post": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Select",
                name: "teammateId",
                validators: [],
                label: "AI Teammate",
                attributes: {
                  options: [],
                },
              },
              {
                componentName: "Select",
                name: "structureId",
                validators: ["required"],
                label: "Structure",
                attributes: {
                  options: [
                    { value: "how-to", text: "How-to Guide" },
                    { value: "listicle", text: "Listicle" },
                    { value: "news-update", text: "News Update" },
                    { value: "opinion", text: "Opinion Piece" },
                    { value: "case-study", text: "Case Study" },
                    { value: "tutorial", text: "Tutorial" },
                    { value: "comparison", text: "Comparison Post" },
                    { value: "FAQ", text: "FAQ" },
                    { value: "checklist", text: "Checklist" },
                    { value: "other", text: "Other" },
                  ],
                },
              },
              /* {
                componentName: "Input",
                name: "name",
                validators: [],
                label: "Name",
                attributes: {
                  placeholder: "Insert the name of your blog post (optional)...",
                },
              }, */
              {
                componentName: "Message",
                name: "description",
                validators: ["required"],
                label: "Description",
                attributes: {
                  placeholder: "Insert the description of your blog post...",
                },
              },
              {
                componentName: "SeoKeywords",
                name: "keywords",
                validators: ["required"],
                label: "Keywords",
                attributes: {
                  placeholder: "Insert the keywords you'd like to optimize for (separate each keyword with a comma)...",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          status: false,
          id: "outline",
          text: "Preparing blog post outline",
          handler: TOOLS.RUN_OUTLINE,
          args: { outlineId: OUTLINE_IDS.BLOG_POST },
        },
        {
          status: false,
          id: "post",
          text: "Writing blog post",
          handler: TOOLS.RUN_WRITE_LONG,
          args: { writingId: WRITING_IDS.BLOG_POST_SEO, keyName: "outlineName", keyContent: "outlineContentJSON" },
        },
        {
          status: false,
          id: "metaTitle",
          text: "Writing meta title",
          handler: TOOLS.RUN_WRITE,
          args: { writingId: WRITING_IDS.BLOG_POST_META_TITLE },
        },
        {
          status: false,
          id: "metaPermalink",
          text: "Writing meta permalink",
          handler: TOOLS.RUN_WRITE,
          args: { writingId: WRITING_IDS.BLOG_POST_META_PERMALINK },
        },
        {
          status: false,
          id: "metaDescription",
          text: "Writing meta description",
          handler: TOOLS.RUN_WRITE,
          args: { writingId: WRITING_IDS.BLOG_POST_META_DESCRIPTION },
        },
        {
          status: false,
          id: "concat",
          text: "Merge post and description",
          handler: TOOLS.TEXT_CONCAT,
          args: {
            variables: [
              { key: "metaTitleContent", prefix: "<h1>Meta Title</h1>" },
              { key: "metaPermalinkContent", prefix: "<h1>Meta Permalink</h1>" },
              { key: "metaDescriptionContent", prefix: "<h1>Meta Description</h1>" },
              { key: "keywords", prefix: "<h1>Meta Keywords</h1>" },
              { key: "postContent", prefix: "<h1>Content</h1>" },
            ],
          },
        },
        {
          status: false,
          id: "document",
          text: "Creating document",
          handler: TOOLS.CREATE_DOCUMENT,
          args: { name: "postName", content: "concatContent" },
        },
      ],
      message: {
        type: "document",
        content: `Done! The blog post <a href="$HOST/$projectId/documents/$documentDocumentId" class="document-link">
<span class='document-name'>$postName <em>(ID: $documentDocumentId)</em></span></a> is ready.`,
      },
    },
  },
  [TASKS.DRAW_BLOG_POST_COVER]: {
    format: "form",
    headline: "Tell Me More",
    firstMessage: `Imagine an image and follow those requirements:\n
Size: $size\n
Description: $description`,
    stateMachine: {
      id: "blog-post-cover",
      initialStep: "step-post",
      context: {
        currentStep: "step-post",
        formData: {
          size: "1024x1024",
          teammateId: "",
          description: "",
        },
      },
      steps: {
        "step-post": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Select",
                name: "teammateId",
                validators: [],
                label: "AI Teammate",
                attributes: {
                  options: [],
                },
              },
              {
                componentName: "Select",
                name: "size",
                validators: ["required"],
                label: "Size",
                attributes: {
                  options: [
                    { value: "1024x1024", text: "Square" },
                    { value: "1024x1792", text: "Vertical" },
                    { value: "1792x1024", text: "Horizontal" },
                  ],
                },
              },
              {
                componentName: "Message",
                name: "description",
                validators: ["required"],
                label: "Description",
                attributes: {
                  placeholder: "Insert the description of your blog post cover...",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          status: false,
          id: "image",
          text: "Creating the image",
          handler: TOOLS.RUN_IMAGE,
          args: {},
        },
      ],
      message: {
        type: "document",
        content: `Done!`,
        contentSuffix: `$imageContent`,
      },
    },
  },
  [TASKS.RETRIEVAL_QA]: {
    format: "form",
    headline: "Tell Me More",
    firstMessage: `Answer the following question:\n
Question: $query
`,
    stateMachine: {
      id: "retrieval-qa",
      initialStep: "step-post",
      context: {
        currentStep: "step-post",
        formData: {
          teammateId: "",
          query: "",
        },
      },
      steps: {
        "step-post": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Select",
                name: "teammateId",
                validators: [],
                label: "AI Teammate",
                attributes: {
                  options: [],
                },
              },
              {
                componentName: "Message",
                name: "query",
                validators: ["required"],
                label: "Question",
                attributes: {
                  placeholder: "Insert the question...",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          status: false,
          id: "qa",
          text: "Searching through your documents and crafting the most appropriate answer...",
          handler: TOOLS.QA_RETRIEVAL,
          args: {},
        },
      ],
      message: {
        content: `$qaAnswer`,
      },
    },
  },
  [TASKS.TRANSCRIBE_MEDIA]: { format: "file", headline: "" },
  [TASKS.TRANSCRIBE_YOUTUBE]: {
    format: "form",
    headline: "Tell Me More",
    firstMessage: `Transcribe this video: $youtubeUrl
`,
    stateMachine: {
      id: "youtube",
      initialStep: "step-post",
      context: {
        currentStep: "step-post",
        formData: {
          youtubeUrl: "",
        },
      },
      steps: {
        "step-post": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Url",
                name: "youtubeUrl",
                validators: ["required"],
                label: "Youtube URL",
                attributes: {
                  placeholder: "Copy paste the youtube URL...",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          status: false,
          id: "transcript",
          text: "Transcription",
          handler: TOOLS.YOUTUBE_TRANSCRIPTION,
          args: {},
        },
      ],
      message: {
        content: `Youtube Video Transcription:
$transcriptContent`,
      },
    },
  },
  [TASKS.SUMMARIZE_TEXT]: {
    format: "form",
    headline: "Tell Me More",
    firstMessage: `Summarize and extract the key points of the following text:\n
Text: $text
`,
    stateMachine: {
      id: "summarize-text",
      initialStep: "step-post",
      context: {
        currentStep: "step-post",
        formData: {
          text: "",
        },
      },
      steps: {
        "step-post": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Message",
                name: "text",
                validators: ["required"],
                label: "Text",
                attributes: {
                  placeholder: "Copy paste the text...",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          status: false,
          id: "summary",
          text: "Reading and summarizing",
          handler: TOOLS.TEXT_SUMMARY,
          args: {},
        },
      ],
      message: {
        content: `$summaryContent`,
      },
    },
  },
  /* [TASKS.SUMMARIZE_PDF]: {
    format: "form",
    headline: "Tell Me More",
    firstMessage: `Summarize and extract the key points of the following PDF file.\n`,
    stateMachine: {
      id: "summarize-pdf",
      initialStep: "step-post",
      context: {
        currentStep: "step-post",
        formData: {
          text: "",
        },
      },
      steps: {
        "step-post": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Message",
                name: "text",
                validators: ["required"],
                label: "Text",
                attributes: {
                  placeholder: "Copy paste the text...",
                },
              },
            ],
          },
        },
      },
    },
    storeDispatch: `projectsTasksTools/${CREATE_JOB}`,
    defaultData: {
      tasksList: [
        {
          status: false,
          id: "summary",
          text: "Reading and summarizing",
          handler: TOOLS.TEXT_SUMMARY,
          args: {},
        },
      ],
      message: {
        content: `$summaryContent`,
      },
    },
  }, */
};

export const ACTION_BY_TEAMMATE = {
  [TASKS.WRITE_BLOG_POST]: TEAMMATES.COPYWRITER,
  [TASKS.WRITE_BLOG_POST_SEO]: TEAMMATES.COPYWRITER,
  [TASKS.WRITE_BLOG_POST_SEO_LONG]: TEAMMATES.COPYWRITER,
  [TASKS.WRITE_BLOG_POST_SEO_DESCRIPTION]: TEAMMATES.COPYWRITER,
  [TASKS.DRAW_BLOG_POST_COVER]: TEAMMATES.ILLUSTRATOR,
  [TASKS.RETRIEVAL_QA]: TEAMMATES.SUPPORT,
};
